/* MediaDialog.module.scss */
.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.dialogContent {
  padding: 20px;
  border-radius: 8px;
  max-width: 90vw;
  max-height: 90vh;
  text-align: center;
  position: relative;
  height: fit-content;
}

.media {
  width: 100%;
  height: auto;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: red;
  font-size: 20px;
  cursor: pointer;
}
