// Tabs

// Variables

$tabs-item-height: 42px;

.tabs-bordered {
  position: relative;

  &:after {
    content: "";
    left: 0;
    width: 100%;
    bottom: 0;
    margin: 0;
    position: absolute;
    height: 1px;
    background: $gray-400;
  }
}

.tab-item-wrapper {
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: $transition-base;

  &.active {
    opacity: 1;
    height: auto;
    overflow: auto;
    visibility: visible;
  }
}
