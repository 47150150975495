//  Core

body {
  .MuiCard-root {
    @include border-radius($border-radius-lg);

    .card-title {
      font-size: $font-size-lg;
    }

    .MuiCardContent-root {
      padding: $card-spacer-x;
    }
  }
}

button.card {
  outline: none;
}

.card-text {
  color: $gray-800;
}

.card-box-alt {
  text-align: center;

  .card-icon-wrapper {
    height: 76px !important;
    line-height: 58px !important;
    width: 76px !important;
    margin: -44px auto 24px;
    border: $card-bg solid 6px;
  }
}

.card-border-top {
  border-top-width: 3px;
  border-top-style: solid;
}

.card-badges {
  position: absolute;
  right: $card-spacer-y;
  top: $card-spacer-y;
  z-index: 2;

  & > * {
    box-shadow: $box-shadow-layout-bottom;
  }
}

.card-badges-bottom {
  top: auto;
  bottom: $card-spacer-y;
}

.card-transparent {
  background: none !important;
  box-shadow: 0 0 0 0 transparent !important;
}

// Boxes

.card {
  box-shadow: $box-shadow-layout-bottom;
}

body {
  .MuiCard-root.card-box {
    overflow: visible;
  }
}

.card-box {
  border: 1px solid $card-border-color;

  .card-footer,
  .card-header {
    border-width: 1px;
    border-color: $list-group-border-color;
  }

  .card-header {
    display: flex;
    align-items: center;
  }

  .card-header--title {
    flex-grow: 1;

    & > b {
      display: block;
    }

    & > small {
      text-transform: uppercase;
      display: block;
      opacity: .3;
      font-weight: bold;


    }
  }

  &.card-box-border-bottom {
    border-top-color: $list-group-border-color !important;
    border-right-color: $list-group-border-color !important;
    border-left-color: $list-group-border-color !important;
    border-bottom-width: 4px;
  }
}



.MuiCard-root {
  & > .bg-composed-wrapper {
    top: -1px;
    margin-left: -1px;
    margin-right: -1px;
    margin-bottom: -2px;
    z-index: 6;
  }
}

// Tasks wrapper

.task-wrapper {
  .task-item {
    position: relative;
    padding: 0 0 ($spacer * 2);

    &:last-child {
      padding-bottom: 0;
    }
  }

}

// Align elements

.align-box-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

// Chat wrapper

.chat-wrapper {
  .chat-item {

    .chat-box {
      position: relative;
      opacity: 1;
      border: 0;
      padding: ($spacer / 1.2) ($spacer * 2);
      @include border-radius($border-radius-lg * 2);
      border-top-left-radius: $border-radius-sm;
      max-width: 50%;
      min-width: 100%;
      font-size: $font-size-sm;

      p {
        margin-bottom: ($spacer / 3);
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .avatar-icon-wrapper {
      margin-right: ($spacer / 2);
    }
  }

  .chat-item-reverse {
    .chat-box {
      border-top-left-radius: ($border-radius-lg * 2);
      border-top-right-radius: $border-radius-sm;
    }

    .avatar-icon-wrapper {
      margin-left: ($spacer / 2);
      margin-right: 0;
    }
  }
}

// File manager

.file-manager-wrapper {
  .file-manager-item {
    background: transparent;
  }
}

// Card indicator

.card-indicator {
  position: absolute;
  height: 60%;
  top: 20%;
  width: 6px;
  @include border-radius($border-radius-lg);
  left: -4px;
}

// Card absolute actions

.card-tr-actions {
  position: absolute;
  right: ($spacer);
  top: ($spacer);
}



// Show hide container

.hover-show-hide-container {
  .hover-show-wrapper {
    display: none;
  }

  .hover-hide-wrapper {
    display: block;
  }

  &:hover {
    .hover-show-wrapper {
      display: block;
    }

    .hover-hide-wrapper {
      display: none;
    }
  }
}

// Overlay


.image-title-overlay {
  position: relative;

  img {
    transition: $transition-base;
  }

  &--bottom {
    background: -moz-linear-gradient(top,  rgba($black,0) 0%, rgba($black,0.8) 100%);
    background: -webkit-linear-gradient(top,  rgba($black,0) 0%,rgba($black,0.8) 100%);
    background: linear-gradient(to bottom,  rgba($black,0) 0%,rgba($black,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
    transition: $transition-base;
    z-index: 3;

    position: absolute;
    bottom: 0;
    width: 100%;
    padding: ($card-spacer-x * 2) $card-spacer-x ($card-spacer-x);
    @include border-bottom-radius(inherit);
  }

  &:hover {

    img {
      opacity: .95;
    }

    .image-title-overlay--bottom {
      padding: ($card-spacer-x * 3) $card-spacer-x ($card-spacer-x);
    }
  }
}

// Hover cards

.card-box-hover-rise {
  transition: $transition-base !important;

  &:hover {
    box-shadow: $box-shadow-layout-bottom-accent;
    transform: translateY(-15px);
  }

  &.card-box-hover {
    &:hover {
      box-shadow: $box-shadow-layout-bottom-accent;
      transform: translateY(0);
    }
  }
}

.card-box-hover-rise-alt {
  transition: $transition-base !important;

  &:hover {
    box-shadow: $modal-content-box-shadow-xs;
    transform: translateY(-15px);
  }

  &.card-box-hover {
    &:hover {
      transform: translateY(0);
    }
  }
}

.card-box-hover-rise-alt-lg {
  transition: $transition-base !important;

  &:hover {
    box-shadow: $modal-content-box-shadow-xs;
    transform: translateY(-35px);
  }

  &.card-box-hover {
    &:hover {
      transform: translateY(0);
    }
  }
}

// Sparklines


.sparkline-full-wrapper {
  overflow: hidden;
  height: 140px;
  position: relative;

  &:last-child {
    margin: 0 -1px -1px;
    @include border-bottom-radius(inherit);
  }

  &--sm {
    height: 100px;
  }

  &--lg {
    height: 160px;
  }

  &--xl {
    height: 221px;
  }

  &--xxl {
    height: 337px;
  }
}

// Overlay cards

.card-chart-overlay {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  opacity: .25;
  z-index: 5;
}

.card-content-overlay {
  position: relative;
  z-index: 6;
}

// Avatars


.card-body-avatar {
  position: relative;
  padding-top: calc(#{$card-spacer-x} + 50px) !important;

  & > .avatar-icon-wrapper {
    margin: 0;
    position: absolute;
    height: 120px;
    width: 120px;
    top: -60px;
    z-index: 3;

    .avatar-icon {
      height: 100%;
      width: 100%;
      box-shadow: $box-shadow-layout-bottom;
    }

    img {
      max-width: 100%;
      height: auto;
      width: 100%;
    }

    &--sm {
      height: 80px;
      width: 80px;
      top: -40px;
    }
  }

  &.text-center {
    & > .avatar-icon-wrapper {
      left: 50%;
      margin-left: -60px;

      .avatar-icon {
        margin: 0;
      }

      &--sm {
        margin-left: -40px;
      }
    }
  }
}

.card-body-button {
  padding-top: 50px !important;
  position: relative;
  z-index: 7;
}

.card-body-button-wrapper {
  position: relative;
  z-index: 4;

  .MuiButton-sizeLarge {
    margin-top: -100px;
  }
}

.btn-swatch {
  width: 32px;
  height: 32px;
  margin: ($spacer / 2);
  @include border-radius($border-radius-sm);
  display: inline-block;
  opacity: .7;
  z-index: 10;
  position: relative;
  transition: $transition-base;
  cursor: pointer;
  border: $white solid 1px;
  transform-origin: center;

  &.active,
  &:hover {
    opacity: 1;
    transform: scale(1.3);
    z-index: 15;
    box-shadow: 0 0 0 2px $primary;
  }

  &--lg {
    width: 38px;
    height: 38px;
    border: $white solid 2px;
    box-shadow: 0 0 0 1px $gray-700;
  }
}

// Cards images


.card-img-wrapper {
  position: relative;
  @include border-top-radius(inherit);

  .img-wrapper-overlay {
    width: 100%;
    height: 100%;
    transition: $transition-base;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: inset 0 0 2.3rem .5rem $black;
    display: flex;
    align-items: center;
    align-content: center;
    color: $white;
    @include border-top-radius(inherit);

    @include media-breakpoint-down(sm) {
      & + img {
        width: 100% !important;
      }
    }

    .overlay-btn-wrapper {
      position: relative;
      z-index: 3;
      text-align: center;
      width: 100%;
    }

    &::before {
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 100%;
      height: 100%;
      transition: $transition-base;
      background: $black;
      opacity: .75;
    }

    &--visible {
      visibility: visible;
      opacity: 1;
      position: relative;
      z-index: 5;
    }
  }

  .card-badges {
    z-index: 5;
  }
}

.card-overlay-image {
  position: absolute;
  left: 0;
  top: 0;
}

a.card-img-wrapper {
  display: block;
  overflow: hidden;

  &:hover {
    .img-wrapper-overlay {
      opacity: 1;
      visibility: visible;
    }
  }
}
.surveysContainer {
  background-color: #d7d7d7;
  padding: 15px 27px 0 20px;
  height: 78vh;

  .active{
    background-color: #04D600;
  }

  .inActive{
    background-color: #D60000;
  }

  .surveysHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h6 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      margin: 0;
      margin-left: 15px;

      color: #000000;
    }

    button {
      border: none;
      background: transparent;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      outline: none;

      color: #000000;
    }
  }

  .surveysBody {
    display: flex;
    flex-direction: row;
    margin-top: 19px;

    @media(max-width: 600px){
      flex-direction: column;
      align-items: center;
    }

    .surveysContant {
      background-color: #fff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 47px;
      width: 252px;

      @media(max-width: 768px){
        margin-right: 20px;
      }

      @media(max-width: 600px){
        margin-right: 0;
        margin-top: 10px;
      }

      .contantImage {
        text-align: end;
        font-size: 0;
      }

      .contantText {
        margin: 11px 20px;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;

          color: #000000;
        }

        .answers {
          display: flex;
          flex-direction: column;
          // margin-bottom: 30px;

          form{
            * {
              &:last-child{
                margin-bottom: 0;
              }
            }
          }

          .label {
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: 9px;

            .inputBack {
              position: absolute;
              width: 7px;
              height: 7px;
              background-color: #d9d9d9;
              border-radius: 50%;
            }

            p {
              text-transform: capitalize;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              margin: 0;

              color: #000000;
            }
            .input {
              width: 7px;
              margin-right: 5px;

              &:checked {
                z-index: 99;
              }
            }

            span {
              font-size: 7px;
              text-decoration: underline;
            }
          }
        }
      }

      .contentFooter {
        display: flex;
        flex-direction: row;
        padding: 12px;
        align-items: center;
        justify-content: space-between;

        .activeSection {
          display: flex;
          flex-direction: row;
          align-items: center;

          .activeLight {
            width: 7px;
            height: 7px;
            border-radius: 50%;
          }

          span {
            margin-left: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            text-transform: capitalize;

            color: #000000;
          }
        }

        span {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;

          color: #000000;
        }
      }
    }
  }
}
