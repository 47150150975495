// Switch

@keyframes SwitchOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes SwitchOff {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
