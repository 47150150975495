.subscription_plans {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  min-height: 50vh;

  background-color: white;

  border-radius: 15px;
  padding-bottom: 30px;
  box-shadow: 0 0 2px 0 rgba(56, 56, 56, 0.7);
  -webkit-box-shadow: 0 0 2px 0 rgba(56, 56, 56, 0.7);
  -moz-box-shadow: 0 0 2px 0 rgba(56, 56, 56, 0.7);

  @media (max-width: 768px) {
    width: calc(100% - 32px);
  }

  .cancel_button {
    width: 100%;

    text-align: end;
    padding-right: 30px;
    padding-top: 20px;

    button {
      background-color: $secondary-color;
      color: white;
      padding: 2px;
      z-index: 999;
    }
  }

  .hide_plan_body {
    display: none;
  }

  .plan_section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .plan_container {
    margin-top: 30px;
    box-shadow: 1px 0px 20px 0px #8297c026;
    border-radius: 20px;
    padding: 24px;
    background: linear-gradient(
      157.38deg,
      #f4ecff 1.4%,
      rgba(244, 236, 255, 0) 98.72%
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 242px;
    position: relative;
    overflow: hidden;

    .active_label {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .line {
      width: 100%;
      height: 0.5px;
      background-color: #dadada;
      margin-top: 24px;
    }

    .plan_name {
      margin-top: 12px;

      .name {
        margin: 0;
        line-height: 24px;
        font-weight: 600;
      }
    }

    .plan_description {
      display: flex;
      flex-direction: column;
      margin-top: 23px;
      padding: 0 13px;

      .description_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;

        .description_text {
          margin: 0;
          font-size: 16px;
        }
      }
    }

    .price_container {
      margin-top: 24px;

      .price {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }
    }

    .choose_button_content {
      width: fit-content;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      margin-top: 24px;

      .choose_button {
        transition: all 0.5s;
        background-color: $secondary-color;
        color: white;
        border: none;

        padding: 8px 12px;
        border-radius: 7px;
        display: flex;
        gap: 4px;
        transform: translateY(0);

        &:hover {
          transform: translateY(-3px);
          transition: all 0.5s;
        }
      }
    }
  }

  .activate_button {
    transition: all 0.5s;
    background-color: $secondary-color;
    color: white;
    border: none;

    margin-top: 20px;
    padding: 8px 12px;
    border-radius: 7px;
    display: flex;
    gap: 4px;
    transform: translateY(0);

    &:hover {
      transform: translateY(-3px);
      transition: all 0.5s;
    }
  }
}
