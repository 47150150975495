.contacts_field {
  padding: 25px 0;
  display: flex;
  justify-content: center;
  width: 100%;

  .contacts_contant {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 50%;
    border-radius: 15px;
    margin-top: 25px;
    padding: 30px 0;
    box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);
    -webkit-box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);
    -moz-box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);
    z-index: 3;
    position: relative;

    .contacts_edit_button {
      position: absolute;
      top: 15px;
      right: 20px;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
        transition-duration: 0.5s;
      }
    }

    .contacts_title {
      display: flex;
      flex-direction: row;
      justify-content: center;

      div {
        display: flex;
        align-items: center;
        margin-left: 20px;
      }
    }

    .contacts_container {
      width: 85%;
      position: relative;

      .contact_subtitle {
        font-size: 24px;
        font-weight: 600;
        margin-top: 20px;
      }
      .contact_text {
        margin-top: 5px;
      }
    }
  }
}
