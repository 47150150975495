.form_container {
  padding: 25px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  @media (max-width: 480px) {
    padding-top: 40px;
  }

  .form_back_btn_field {
    position: fixed;
    top: 99px;
    left: 339px;
    z-index: 999;

    @media (max-width: 1100px) {
      left: 20px;
    }

    @media (max-width: 768px) {
      left: 10px;
    }

    @media (max-width: 480px) {
      top: 85px;
      left: 5%;
    }

    .form_back_btn {
      background: $secondary-color;
      color: white;
      padding: 6px 16px 6px 10px;
    }
  }

  .create_edit_form {
    width: 50%;

    @media (max-width: 1100px) {
      width: 80%;
    }

    @media (max-width: 480px) {
      width: 90%;
    }

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 3;

    background-color: white;

    border-radius: 15px;
    padding-bottom: 30px;
    box-shadow: 0 0 2px 0 rgba(56, 56, 56, 0.7);
    -webkit-box-shadow: 0 0 2px 0 rgba(56, 56, 56, 0.7);
    -moz-box-shadow: 0 0 2px 0 rgba(56, 56, 56, 0.7);

    .cancel_button {
      width: 100%;

      text-align: end;
      padding-right: 30px;
      padding-top: 20px;

      button {
        background-color: $secondary-color;
        color: white;
        padding: 2px;
        z-index: 999;
      }
    }

    .field_Container {
      width: 85%;

      margin-top: 30px;

      position: relative;

      display: flex;
      justify-content: center;

      input[value=""]::-webkit-datetime-edit {
        color: transparent;
      }
      input[value=""]:focus::-webkit-datetime-edit {
        color: black !important;
      }

      &.direction_column {
        flex-direction: column;
      }

      .location {
        width: 100%;
        position: relative;

        .hide_suggestion {
          display: none;
        }

        .show_suggestion {
          position: absolute;
          background-color: #d7d7d7;
          width: 100%;
          z-index: 10;

          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            cursor: pointer;

            li {
              padding: 10px;
              color: #4b4646;

              &:hover {
                background-color: #bfbfbf;
              }
            }
          }
        }
      }

      .image_wrapper {
        display: flex;
        flex-direction: column;
        height: 150px;
        width: 150px;
        cursor: pointer;
        z-index: 100;

        .create_edit_image_input {
          display: none;
        }

        .create_edit_image {
          height: 100%;
          width: 100%;
          overflow: hidden;

          border: 1px solid rgb(213, 209, 209);
          background-color: white;
          cursor: pointer;

          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          & > svg {
            width: 40px;
            height: 40px;

            color: rgb(224, 219, 219);

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          & > img {
            height: 100%;
            width: 100%;

            -o-object-fit: contain;
            object-fit: contain;
          }

          &.create_edit_image_error {
            border: 1px solid red;
          }
        }
      }

      .array_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        gap: 30px;

        .array_input {
          margin-top: 30px;
        }

        .array_field {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

          .array_delete_icon {
            margin: 30px 0 0 20px;
            cursor: pointer;
          }

          .custom_styles {
            width: 20px;
          }

          .about_delete_icon {
            margin: 0 0 0 20px;

            &:hover {
              path {
                fill: $secondary-color;
              }
            }
          }

          .drag_icon {
            fill: $primary-color;
            &:hover {
              cursor: pointer;
            }
          }
        }

        .array_field_desc {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

          svg {
            margin: 30px 0 0 20px;
          }

          div {
            width: 100%;
          }
        }

        .array_input {
          margin-top: 30px;
        }
      }
      &.drag_container {
        gap: 30px;
      }
    }

    .width_95 {
      width: 95%;
    }

    .width_100 {
      width: 100%;
    }

    .swiper {
      width: 50%;

      .swiper-button-prev,
      .swiper-button-next {
        color: $secondary-color;
      }

      .swiper-pagination-bullet {
        background-color: $secondary-color !important;
      }

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 10%;
      }
    }

    .form_button {
      background-color: $secondary-color;
      color: white;
      border: none;

      margin-top: 30px;
      padding: 10px 15px;
      border-radius: 7px;
      display: flex;
      gap: 4px;

      & > svg {
        margin-left: 5px;
        color: white;
      }
    }
  }

  .product_create {
    width: 96%;
    margin-top: 40px;
    padding: 30px;
  }

  .other_services {
    width: 100%;
    margin-top: -30px;
  }

  .editor_wrapper {
    width: 100%;

    .editor_wrapper_description {
      margin: 3px 14px 0 14px;
    }

    &.editor_wrapper_error {
      > div {
        > div {
          border: solid #d32f2f;
        }
      }
    }

    .editor_error {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      text-align: left;
      margin: 3px 14px 0 14px;
      color: #d32f2f;
    }
  }

  .save_icon {
    margin-left: 5px;
  }
}
.create_edit {
  position: fixed;
  width: 100%;
  display: flex;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;

  .popup {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(10%);

    @media (max-width: 1099px) {
      transform: translateX(0);
    }
  }
}

.question_filed_container {
  display: block !important;
  width: 100% !important;

  .field_title {
    font-weight: 700;
    color: #2b032b;
    margin-bottom: 8px;
    font-size: 14px;
  }

  .question_array_field {
    margin-top: 5px !important;
    column-gap: 10px;

    svg {
      margin: 0 !important;
    }
    .check_and_delete {
      display: flex;
      align-items: center;
      width: fit-content !important;

      .MuiFormControlLabel-label {
        font-size: 14px !important;
      }
    }
  }
}

.explanation_checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  white-space: nowrap;
  margin-top: 30px;

  .active_text {
    margin: 0;
    color: black;
    font-weight: 700;
    font-size: 14px;
  }
}

.pairing_questions_array {
  margin-top: 5px !important;
  display: flex;
  column-gap: 15px;
  flex-direction: row !important;

  .arrows_icon {
    margin: 0 !important;
  }

  .question_delete_icon {
    margin: 0 !important;
  }

  .action_button {
    background-color: $transparent !important;
    height: fit-content !important;

    &:hover {
      background-color: $transparent !important;

      .question_delete_icon {
        path {
          fill: $secondary-color !important;
        }
      }
    }
  }
}

.spacing {
  margin-top: 0 !important;
}

.min_width {
  min-width: 24px !important;
}

.width_90 {
  width: 100% !important;
  align-items: start !important;
  padding: 30px !important;
  display: grid !important;
  column-gap: 15px;
  grid-template-columns: repeat(12, 1fr);
}

.padding {
  padding: 30px !important;
  display: block !important;
}

.back_btn_question {
  position: initial !important;
  margin-bottom: 30px;
}

.column_4 {
  grid-column: span 4;
}

.column_6 {
  grid-column: span 6;
}

.column_12 {
  grid-column: span 12;
}

.save_button_filed {
  margin: auto;
}

.question_file {
  display: none;
}

.file_container {
  display: flex;
  justify-content: center;

  .file_label_relative {
    position: relative;
  }
  .question_file_label {
    width: 100%;
    max-width: 400px;
    min-height: 250px;
    border: 1px solid rgb(194, 193, 193);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    border-radius: 12px;

    .uploaded_file {
      max-width: 95%;
      max-height: 95%;
      border-radius: 12px;
    }
    .uploaded_file_video {
      max-width: 95%;
      border-radius: 12px;
    }

    svg {
      path {
        fill: grey;
      }
    }
  }
}

.questions_save_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  transform: translateX(-70px);
  margin-top: 30px !important;

  .form_button {
    margin: 0 !important;
  }

  .status_field {
    height: 100%;
    background: #f3f3f3;
    padding: 5px 10px;
    border-radius: 7px;
    width: 140px;

    .MuiTypography-root {
      font-size: 16px !important;
    }
  }
}

.width_100 {
  width: 100% !important;

  .field_title {
    font-weight: 700;
    color: #2b032b;
    margin-bottom: 8px;
    font-size: 14px;
  }
}

.hint_text {
  font-size: 12px;
  color: #2b032b;
  margin-bottom: 5px;
  &.italic {
    font-style: italic;
  }
}

.add_button {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;

  background-color: $secondary-color !important;
  color: white !important;

  margin-bottom: 30px !important;
}

.add_button_margin {
  margin: 30px 0 0 !important;
}
.question_filed_container {
  display: block !important;
  width: 100% !important;

  .editor_wrapper {
    width: 100%;
    height: fit-content;

    .editor_wrapper_description {
      margin: 3px 0 0;

      .editor_label {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    &.editor_wrapper_error {
      > div {
        > div {
          border: solid #d32f2f;
        }
      }
    }

    .editor_error {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      text-align: left;
      margin: 3px 14px 0 14px;
      color: #d32f2f;
    }
  }

  .editor_container {
    border: 1px solid #d1d2db;
    border-radius: 0.29rem;
  }

  .field_title {
    font-weight: 700;
    color: #2b032b;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .question_array_field {
    margin-top: 5px !important;
    column-gap: 10px;

    svg {
      margin: 0 !important;
    }
    .check_and_delete {
      display: flex;
      align-items: center;
      width: fit-content !important;

      .MuiFormControlLabel-label {
        font-size: 14px;
      }
    }
  }
}

.pairing_questions_array {
  margin-top: 5px !important;
  display: flex;
  column-gap: 15px;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;

  .arrows_icon {
    margin: 0 !important;
  }

  .question_delete_icon {
    margin: 0 !important;
  }

  .action_button {
    background-color: $transparent !important;
    height: fit-content;

    &:hover {
      background-color: $transparent !important;

      .question_delete_icon {
        path {
          fill: $secondary-color !important;
        }
      }
    }
  }
}

.spacing {
  margin-top: 0 !important;
}

.min_width {
  min-width: 24px !important;
}

.width_90 {
  width: 100% !important;
  align-items: start !important;
  padding: 30px !important;
  display: grid !important;
  column-gap: 15px;
  grid-template-columns: repeat(12, 1fr);
}

.padding {
  padding: 30px !important;
  display: block !important;
}

.back_btn_question {
  position: initial !important;
  margin-bottom: 30px;
}

.column_6 {
  grid-column: span 6;
}

.column_12 {
  grid-column: span 12;
}

.save_button_filed {
  margin: auto;
}

.width_100 {
  width: 100% !important;

  .field_title {
    font-weight: 700;
    color: #2b032b;
    margin-bottom: 8px;
    font-size: 14px;
  }
}

.hint_text {
  font-size: 12px;
  color: #2b032b;
  margin-bottom: 5px;

  &.italic {
    font-style: italic;
  }
}

.add_button {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;

  background-color: $secondary-color !important;
  color: white !important;

  margin-bottom: 30px !important;
}

.add_button_margin {
  margin: 30px 0 0 !important;
}
