.page_container {
  padding: 30px;

  @include phone {
    padding: 0 10px;
  }

  .MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
  }

  .table_title {
    font-size: 24px;
    font-weight: 700;
    margin: 9px 0;
    color: $primary-color-light;
  }

  .table_head_title {
    font-weight: 700;
    margin: 0;
  }

  .actions_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;

    .action_button {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    .edit_icon {
      width: 20px !important;
      height: 20px !important;

      &:hover {
        color: $secondary-color;
      }
    }
  }
}
