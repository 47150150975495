.about_container {
  width: 100%;
  padding: 30px;

  @media (max-width: 480px) {
    padding: 0 15px;
  }

  .head_section {
    width: 65%;
    text-align: center;
    margin: auto;

    @include ipad {
      width: 100%;
    }

    .about_title {
      font-size: 32px;
      @include phone {
        font-size: 25px;
      }
      span {
        color: $primary-color;
      }
    }

    .about_description {
      font-size: 13px;
      margin-top: 20px;
    }
  }

  .main_section {
    text-align: center;
    width: 100%;
    margin-top: 30px;

    .about_subtitle {
      font-size: 20px;
    }

    .about_subdesc {
      font-size: 13px;
      margin-top: 20px;
    }
  }

  .about_elements_container {
    margin: auto;
    width: fit-content;

    .element {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;

      .element_number {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
      }

      .element_name {
        margin: 0;
      }
    }
  }
}
