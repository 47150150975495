.team_field {
  padding: 25px 0;
  display: flex;
  justify-content: center;
  width: 100%;

  .team_contant {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 50%;
    border-radius: 15px;
    margin-top: 25px;
    padding: 30px 0;
    box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);
    -webkit-box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);
    -moz-box-shadow: 0 0 9px 0 rgba(56, 56, 56, 0.7);
    z-index: 3;
    position: relative;

    .team_edit_button {
      position: absolute;
      top: 15px;
      right: 20px;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
        transition-duration: 0.5s;
      }
    }

    .team_title {
      display: flex;
      flex-direction: row;
      justify-content: center;

      div {
        display: flex;
        align-items: center;
        margin-left: 20px;
      }
    }

    .team_container {
      width: 85%;
      position: relative;

      .team_subtitle {
        font-size: 24px;
        font-weight: 600;
        margin-top: 20px;
      }
      .team_text {
        margin-top: 5px;
      }

      .swiper {
        width: 50%;

        .swiper-button-prev,
        .swiper-button-next {
          color: $secondary-color;
        }

        .swiper-pagination-bullet {
          background-color: $secondary-color !important;
        }
        .swiper-pagination {
          bottom: -5px;
        }

        .swiper-slide {
          padding: 17px 45px;

          @media (max-width: 1440px) {
            padding: 17px 0;
          }
        }

        img {
          width: 250px;
          height: 250px !important;
          object-fit: contain;
          border-radius: 10%;
        }
      }
    }
  }
}
