.array_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 30px;
  padding: 20px;
  border: 1px dashed rgb(213, 209, 209);

  .array_input {
    margin-top: 30px;
  }

  .array_field {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .array_delete_icon {
      margin: 30px 0 0 20px;
      cursor: pointer;
    }

    .custom_styles {
      width: 20px;
    }

    .about_delete_icon {
      margin: 0 0 0 20px;

      &:hover {
        path {
          fill: $secondary-color;
        }
      }
    }

    .drag_icon {
      fill: $primary-color;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .array_field_desc {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin: 30px 0 0 20px;
    }

    div {
      width: 100%;
    }
  }

  .array_input {
    margin-top: 30px;
  }

  .array_wrapper_image {
    display: flex;
    row-gap: 50px;
    column-gap: 15px;

    .create_edit_image_input {
      display: none;
    }

    .create_edit_image {
      height: 150px;
      width: 150px;
      overflow: hidden;

      border: 1px solid rgb(213, 209, 209);
      background-color: white;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      & > svg {
        width: 40px;
        height: 40px;

        color: rgb(224, 219, 219);

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      & > img {
        height: 100%;
        width: 100%;

        -o-object-fit: contain;
        object-fit: contain;
      }

      &.create_edit_image_error {
        border: 1px solid red;
      }
    }

    .uploaded_images_field {
      display: flex;
      flex-wrap: nowrap;
      gap: 15px;
      overflow: scroll;
      width: -webkit-fill-available;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .image_wrapper {
      height: auto;
      width: fit-content;
      position: relative;

      display: flex;
      flex-direction: row;
      align-items: center;

      .delete_image_wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        z-index: 10;
        display: none;
        align-items: center;
        justify-content: center;
        transition: all 0.5;

        .delete_icon {
          position: absolute;
          right: 10px;
          top: 10px;
        }

        svg {
          color: white;
        }
      }

      &:hover {
        .delete_image_wrapper {
          display: flex;
          transition: all 0.5;
        }
      }

      .uploaded_image_wrapper {
        position: relative;
        width: 150px;
        height: 150px;
        border: 1px solid rgb(213, 209, 209);

        .uploaded_image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      label {
        width: 150px;
        height: 150px;
        margin-right: 10px;
        @media (max-width: 1024px) {
          width: 100px;
          height: 100px;
        }
      }
    }

    @media (max-width: 1440px) {
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      row-gap: 30px;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
}
