.departments_container {
  padding: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include phone {
    padding: 0 15px;
  }

  .department_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .sections_department {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include ipad {
        width: 80%;
      }

      @include phone {
        width: 100%;
      }

      .grid_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
        row-gap: 40px;

        @include phone {
          grid-template-columns: 1fr;
          column-gap: 0;
          align-items: center;
        }

        > a {
          &:hover {
            color: #3b3e66;
          }
        }

        .department_grid {
          background: $white;
          width: 330px;
          height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: 0.4s;
          box-shadow: 1px 0 20px $topic-shadow-color;
          border-radius: 3px;
          border-color: $topic-hover-color;

          &:hover {
            border: 1px solid $topic-hover-color;
            box-shadow: 1px 0 20px $topic-shadow-hover-color;
            transform: scale(1.077);
            cursor: pointer;
          }

          .grid_image {
            padding-bottom: 20px;
            position: relative;

            &::after {
              position: absolute;
              content: "";
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 40px;
              height: 3px;
              background-color: $topic-line-color;
            }
            img {
              max-width: 100px;
              max-height: 60px;
            }
          }

          .grid_name {
            margin: 16px 0;
          }

          .element_header {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin: 20px 20px 20px 0;

            .edit_icon {
              width: 20px !important;
              height: 20px !important;

              &:hover {
                path {
                  fill: $secondary-color;
                }
              }
            }
          }
        }
      }
    }
  }
}

.disable_mouse_events {
  pointer-events: none;
  overflow: hidden;
}
