.question_view {
  display: block;
  margin: 30px;
  .card {
    border-radius: 0.75rem;
    transition: box-shadow 300ms ease-out 0ms;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    padding: 30px;

    @media (max-width: 480px) {
      padding: 16px 16px 16px 0;
    }
    .row {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .images_container {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      .image_item {
        width: 150px;
        height: 150px;
        border: 1px solid rgb(213, 209, 209);
        background-color: lightgray;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .image {
          width: 100%;
          height: 100%;
        }
      }
    }
    .field_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 0 20px 20px;
      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 5px;
      }
      .content {
        font-size: 14px;
      }

      .explanation_field {
        display: flex;
        flex-direction: column;

        .explanation_type {
          text-transform: capitalize;
        }

        .explanation_content {
          width: 100%;

          .explanation_file {
            max-width: 300px;

            @media (max-width: 480px) {
              max-width: 100%;
            }
          }
        }
      }
    }

    .explanation_container {
      gap: 20px;
    }

    .mr-1 {
      margin-right: 8px;
    }
    .mr-2 {
      margin-right: 16px;
    }
    .w-full {
      width: 100%;
    }
    .w_half {
      width: 50%;
    }
    .w_60 {
      width: 65%;
    }
  }
  .answer_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .correct_answer {
      display: flex;
      align-items: center;
    }
    .answer {
      font-size: 16px;
      padding: 8px 0;
    }
  }

  .pairing_answer_container {
    display: flex;
    align-items: center;
    svg {
      margin: 0 20px;
    }
  }
}
