// Sizing shortcuts

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
  @include deprecate("`size()`", "v4.3.0", "v5");
}

@mixin maxIpad () {
  @media (max-width: 1024px) {
    @content
  }
}

@mixin ipad () {
  @media (max-width: 768px) {
    @content
  }
}

@mixin phone () {
  @media (max-width: 480px) {
    @content
  }
}