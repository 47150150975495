.pages_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  &.no_margin {
    margin-bottom: 0;
  }

  .back_button {
    background: $secondary-color;
    color: white;
    padding: 6px 16px 6px 10px;

    &:hover {
      background: $secondary-color;
      color: white;
    }
  }

  .edit_icon {
    width: 32px !important;
    height: 32px !important;
  }

  .create_icon {
    width: 32px !important;
    height: 32px !important;
    color: $secondary-color;
  }

  .width_400 {
    width: 400px;
    text-align: end;
  }
}

.add_position {
  position: absolute;
  top: 20px;
}
